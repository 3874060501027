var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"align divcol gap",attrs:{"id":"liveData"}},[_c('div',{staticClass:"acenter",staticStyle:{"position":"relative"}},[_c('v-btn',{attrs:{"id":"go-back","icon":"","to":"/events"}},[_c('v-icon',{staticStyle:{"color":"#ffffff !important"}},[_vm._v("mdi-arrow-left")])],1),_c('h2',{staticStyle:{"margin":"0"}},[_vm._v("Event / "+_vm._s(_vm.title))])],1),_c('aside',{staticClass:"container-info acenter"},[_c('div',{staticClass:"divcol"},[_c('label',{staticStyle:{"font-size":"1em","transform":"translateY(5px)"}},[_vm._v("Tickets Sold")]),_c('span',[_vm._v(_vm._s(_vm.ticketsSold))])]),_c('div',{staticClass:"divcol"},[_c('label',{staticStyle:{"font-size":"1em","transform":"translateY(5px)"}},[_vm._v("Your Revenue")]),_c('span',[_vm._v(_vm._s(_vm.incomes.toFixed(2))+" N")])]),_c('label',{staticStyle:{"margin-top":"auto"}},[_vm._v("≈ "+_vm._s((_vm.nearPrice * _vm.incomes).toFixed(2))+" USD")])]),_vm._m(0),_c('section',{staticClass:"container-down"},[_c('div',{staticClass:"container-filter acenter"},_vm._l((_vm.dataFilters),function(item,i){return _c('v-card',{key:i,staticClass:"divcol",class:{ active: item.active },attrs:{"title":item.name.length > 22? item.name : null},on:{"click":function($event){_vm.dataFilters.forEach(function (e) {
              e.active = false;
            });
            item.active = true;}}},[_c('label',[_vm._v(_vm._s(_vm.limitStr(item.name, 22)))]),_c('span',[_vm._v(_vm._s(item.value))])])}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('h2',{staticClass:"p vermobile mt-10",staticStyle:{"font-size":"23px"}},[_vm._v("Orders")]),_c('v-text-field',{staticClass:"search scan",attrs:{"append-icon":_vm.search ? '' : 'mdi-magnify',"placeholder":"Search Account or Scan QR-Code","single-line":"","hide-details":"","clear-icon":"mdi-close","clearable":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-dialog',{attrs:{"width":"420px","content-class":"dialogUrl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":require("@/assets/icons/scan.svg"),"alt":"scan button"}},'img',attrs,false),on))]}}]),model:{value:(_vm.modalQR),callback:function ($$v) {_vm.modalQR=$$v},expression:"modalQR"}},[_c('v-card',{staticClass:"pa-10",attrs:{"id":"modalUrl"}},[_c('StreamBarcodeReader',{on:{"decode":_vm.onDecode,"loaded":_vm.onLoaded}})],1)],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.dataFilters[_vm.dataFilters.findIndex(function (e) { return e.key == 'fans'; })].active ==
          true
        ),expression:"\n          dataFilters[dataFilters.findIndex((e) => e.key == 'fans')].active ==\n          true\n        "}],attrs:{"id":"dataTable","loading":_vm.loading,"search":_vm.search,"headers":_vm.isMobile ? _vm.headersTableMobile : _vm.headersTable,"items":_vm.dataTable,"footer-props":{ 'items-per-page-options': [5, 10, 20, 50, -1] },"mobile-breakpoint":-1},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"eliminarmobile",attrs:{"disabled":_vm.btnDisabled,"loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")]),_vm._v(" Approve")],1),_c('v-btn',{staticClass:"vermobile",staticStyle:{"padding":"1px !important"},attrs:{"min-width":"max-content","max-width":"max-content","min-height":"max-content","height":"max-content","disabled":_vm.btnDisabled,"loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.dataFilters[_vm.dataFilters.findIndex(function (e) { return e.key == 'people'; })].active ==
          true
        ),expression:"\n          dataFilters[dataFilters.findIndex((e) => e.key == 'people')].active ==\n          true\n        "}],attrs:{"id":"dataTable","loading":_vm.loading,"search":_vm.search,"headers":_vm.isMobile ? _vm.headersTableMobilePeople : _vm.headersTablePeople,"items":_vm.dataTablePeople,"footer-props":{ 'items-per-page-options': [5, 10, 20, 50, -1] },"mobile-breakpoint":-1},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"eliminarmobile",attrs:{"disabled":"","loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")]),_vm._v(" Approved")],1),_c('v-btn',{staticClass:"vermobile",staticStyle:{"padding":"1px !important"},attrs:{"min-width":"max-content","max-width":"max-content","min-height":"max-content","height":"max-content","loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.dataFilters[_vm.dataFilters.findIndex(function (e) { return e.key == 'redeemed'; })]
            .active == true
        ),expression:"\n          dataFilters[dataFilters.findIndex((e) => e.key == 'redeemed')]\n            .active == true\n        "}],attrs:{"id":"dataTable","loading":_vm.loading,"search":_vm.search,"headers":_vm.isMobile ? _vm.headersTableMobileRedeemer : _vm.headersTableRedeemer,"items":_vm.dataTableRedeemer,"footer-props":{ 'items-per-page-options': [5, 10, 20, 50, -1] },"mobile-breakpoint":-1},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"eliminarmobile",attrs:{"disabled":"","loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")]),_vm._v(" Approved")],1),_c('v-btn',{staticClass:"vermobile",staticStyle:{"padding":"1px !important"},attrs:{"min-width":"max-content","max-width":"max-content","min-height":"max-content","height":"max-content","loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.dataFilters[_vm.dataFilters.findIndex(function (e) { return e.key == 'orders'; })].active ==
          true
        ),expression:"\n          dataFilters[dataFilters.findIndex((e) => e.key == 'orders')].active ==\n          true\n        "}],attrs:{"id":"dataTable","loading":_vm.loading,"search":_vm.search,"headers":_vm.isMobile ? _vm.headersTableOrdersMobile : _vm.headersTableOrders,"items":_vm.dataTableOrders,"footer-props":{ 'items-per-page-options': [5, 10, 20, 50, -1] },"mobile-breakpoint":-1},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"eliminarmobile",attrs:{"disabled":_vm.btnDisabled,"loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_vm._v("Complete order")]),_c('v-btn',{staticClass:"vermobile",staticStyle:{"padding":"1px !important"},attrs:{"min-width":"max-content","max-width":"max-content","min-height":"max-content","height":"max-content","disabled":_vm.btnDisabled,"loading":item.loadingBtn},on:{"click":function($event){return _vm.approveTicket(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"420px"},model:{value:(_vm.modalSuccess),callback:function ($$v) {_vm.modalSuccess=$$v},expression:"modalSuccess"}},[_c('v-card',{attrs:{"id":"modalSucess"}},[_c('div',{staticClass:"divcol center"},[_c('h3',{staticClass:"p"},[_vm._v(_vm._s(_vm.succesType ? "Success!" : "Error!"))]),_c('p',{staticClass:"p"},[_vm._v(_vm._s(_vm.succesType ? "Your transaction was succesful." : "Your transaction has failed."))])]),_c('div',{staticClass:"divcol center"},[_c('v-btn',{on:{"click":function($event){_vm.modalSuccess=false; _vm.urlTx=''}}},[_vm._v("Ok")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.urlTx),expression:"urlTx"}],staticClass:"acenter",staticStyle:{"gap":".3em"},attrs:{"href":_vm.urlTx,"target":"_blank"}},[_c('span',{staticClass:"p"},[_vm._v("See transaction")]),_c('img',{attrs:{"src":require("@/assets/icons/transaction.svg"),"alt":"link icon"}})])],1)])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"divcol"},[_c('h2',{staticStyle:{"margin-bottom":"1px"}},[_vm._v("Last updates")]),_c('label',{staticStyle:{"font-size":"13px","font-weight":"510"}},[_vm._v("Filter by")])])}]

export { render, staticRenderFns }